<template>
    <v-app>
        <v-card>
            <v-app-bar justify="center" color="matchpal">
                <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
                <v-toolbar-title>Notificações</v-toolbar-title>
            </v-app-bar>
            <v-container>
                <v-row justify="center" dense>
                    <v-col class="col-xs-12 col-sm-12 col-md-9 col-12">
                        <v-card outlined tile>
                            <v-card-title>Configuração</v-card-title>
                            <v-divider />
                            <v-card-text class="text-body-1">
                                {{ statusText }}
                                
                                <div class="text-right">
                                    <v-btn v-if="permission == 'default'" color="matchpalAlert"
                                        @click="askNotificationPermission()">Permitir notificações</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag"
            :error="snackbar.errorFlag" :color="snackbar.color" :hide-close-buttom="snackbar.isConfirmMode">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <v-btn v-if="snackbar.isConfirmMode" color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não</v-btn>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim</v-btn>
            </template>
        </Snackbar>

    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
export default {
    name: "Notificacoes",
    components: {
        Snackbar: () => import('@components/Snackbar.vue'),
    },
    mixins: [ Utils ],
    data: () => ({
        permission: '',
        statusText: '',
    }),
    mounted() {
        if (!("Notification" in window)) {
            this.statusText = "Este navegador não suporta notificações."
        } else {
            this.updatePermission()
        }
    },
    methods: {
        askNotificationPermission: async function(){
            // Let's check if the browser supports notifications
            if (!("Notification" in window)) {
                console.log("This browser does not support notifications.")
            } else if (this.checkNotificationPromise()) {
                Notification.requestPermission().then((permission) => {
                    this.updatePermission()
                })
            } else {
                Notification.requestPermission((permission) => {
                    this.updatePermission()
                })
            }
        },
        checkNotificationPromise: function() {
            // Old browsers do not use this pattern
            try { 
                Notification.requestPermission().then()
            } catch (e) {
                return false
            }

            return true
        },
        updatePermission: function() {
            this.permission = Notification.permission
            switch (this.permission) {
            case 'granted':
                this.statusText = 'Notificações permitidas.'
                break
            case 'denied':
                this.statusText = 'Notificações bloqueadas. Para desbloquear você deve acessar as configurações do navegador.'
                break
            case 'default':
                this.statusText = 'Você precisa autorizar as notificações. Ao clicar no botão abaixo uma janela irá surgir solicitando permissão para as notificações ou um ícone de um sino com um X irá aparecer na barra de endereço do navegador.'
                break
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

    p
        color: #fff
        font-size: 1.5em
        font-weight: bold

</style>