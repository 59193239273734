<template>
    <v-app>
        <v-card>
            <v-app-bar justify="center" color="matchpal">
                <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
                <v-toolbar-title>Plano Contratado e Faturas</v-toolbar-title>
            </v-app-bar>
            <v-container>
                <v-row justify="center" dense>
                    <v-col class="col-xs-12 col-sm-12 col-md-9 col-12">
                        <v-card color="#69D2E7">
                            <v-card-title style="padding-bottom:0px;" class="headline">{{ fatura.descricao }} - R$
                                {{ fatura.valor_total }} </v-card-title>
                            <v-card-title style="padding-top:0px;" class="headline">{{ fatura.status }}</v-card-title>
                            <v-card-subtitle v-text="'Vencimento '+fatura.data_vencimento" />
                            <v-expand-transition>
                                <div align="center">
                                    <v-btn v-if="false & fatura.status == 'Pendente'" class="col-11" color="matchpalAlert"
                                        @click="openBoleto()">Pagar com Boleto</v-btn>
                                    <div class="my-2">
                                        <v-btn v-if="fatura.status == 'Pendente'" class="col-11" color="matchpalAlert"
                                        @click="openPix()">Pagar com Pix</v-btn>
                                    </div>
                                    <v-col v-for="(item, i) in fatura.itens" :key="`A-${i}`"
                                        @click.stop="openItem(item)">
                                        <v-card outlined>
                                            <v-list-item three-line>
                                                <v-list-item-content class="headline">
                                                    <v-list-item-subtitle>{{ item.descricao_tipo }} R$
                                                        {{ item.valor_unitario }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle>{{ item.descricao_data }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle>
                                                        {{ item.quantidade }} x {{ item.valor_unitario }}
                                                        <v-icon v-if="iconItem.includes(item.tipo)" color="#000">search
                                                        </v-icon>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </div>
                            </v-expand-transition>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="dialog" max-width="500px">
                <v-card max-width="500">
                    <v-toolbar color="matchpal" dark>
                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                        <v-icon class="modal-icon-close" @click="dialog = false">close</v-icon>
                    </v-toolbar>
                    <v-list two-line>
                        <v-list-item-group v-model="selected">
                            <template v-for="(item, index) in items">
                                <v-list-item :key="item.title">
                                    <v-list-item-content>
                                        <v-list-item-title>Reservado em {{ item.data_criacao }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.nome_quadra }} - {{ item.data_reservada }}
                                            {{ item.horario_reservado }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>{{ item.nome_jogador }} - {{ item.celular_jogador }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>Aceita por - {{ item.adm_confirmou }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider v-if="index + 1 < items.length" :key="index" />
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogBoleto" max-width="300">
                <v-card max-width="300">
                    <v-toolbar color="matchpal" dark>
                        <v-toolbar-title>{{ fatura.descricao }} - R$ {{ fatura.valor_total }}</v-toolbar-title>
                    </v-toolbar>
                    <v-list two-line>
                        <v-list-item-group v-model="selected">
                            <v-col cols="12">
                                <div class="text-center">
                                    <div class="my-2">
                                        <v-btn class="col-11" color="warning" dark @click="openLinkBoleto()">Visualizar
                                        </v-btn>
                                    </div>
                                    <div class="my-2">
                                        <v-btn class="col-11" color="warning" dark @click="copiarCodigodeBarras()">Copiar código de barras</v-btn>
                                    </div>
                                    <div class="my-2">
                                        <v-btn class="col-11" color="warning" dark @click="openShare()">Copiar link do boleto
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPix" max-width="300">
                <v-card max-width="300">
                    <v-toolbar color="matchpal" dark>
                        <v-toolbar-title>{{ fatura.descricao }} - R$ {{ fatura.valor_total }}</v-toolbar-title>
                    </v-toolbar>
                    <img :src="`data:image/png;base64,${pix.qr_code_base64}`" width="100%">
                    <v-list two-line>
                        <v-list-item-group v-model="selected">
                            <v-col cols="12">
                                <div class="text-center">
                                    <div class="my-2">
                                        <v-btn v-if="pix.url != null" class="col-11" color="warning" dark @click="openLinkPix()">Visualizar
                                        </v-btn>
                                    </div>
                                    <div class="my-2">
                                        <v-btn class="col-11" color="warning" dark @click="copiarCodigoPix()">Copiar código Pix</v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-dialog>
        </v-row>
        <Load :load="load" />
    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
import currency from "currency.js"



export default {
    name: "Fatura",
    components: {
        Load: () => import('@components/Load.vue'),
    },
    mixins: [ Utils ],
    props: {
        faturaId: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        load: true,
        fatura: "",
        title: "",
        iconItem: ["boleto", "reserva"],
        dialog: false,
        selected: [2],
        items: [],
        boleto: [],
        pix:[],
        dialogBoleto: false,
        dialogPix: false,
        phoneSnackbar: ''
    }),
    beforeMount() {
        this.$http({
            method: "post",
            url: "/financeiro/fatura",
            data: {
                fatura_id: this.faturaId
            }
        }).then(response => {
            this.fatura = response.data.fatura
            this.fatura.valor_total = this.formatValue(this.fatura.valor_total)
            this.fatura.status = this.capitalized(this.fatura.status)
            this.fatura.itens.forEach(element => {
                element.valor_unitario = this.formatValue(element.valor_unitario)
            })
            this.load = false
        })
    },
    methods: {
        formatValue: function (valor) {
            return this.formatCurrency(valor)
        },
        openItem: function (item) {
            if (this.iconItem.includes(item.tipo)) {
                this.$http({
                    method: "post",
                    url: "/financeiro/itemfatura/reservas",
                    data: {
                        fin_itemFaturaId: item.id
                    }
                }).then(response => {
                    if (response.data.mensagem == "ok") {
                        this.items = response.data.reservasFatura
                        let cont = this.items.length
                        this.items.forEach(element => {
                            element.celular_jogador = this.formatPhone(
                                element.celular_jogador
                            )
                        })
                        if (cont > 0) {
                            this.dialog = true
                            this.title = "Solicitações aceitas - " + cont
                        }
                    }
                })
            }
        },
        openBoleto: function () {
            // console.log(this.fatura)
            this.dialogBoleto = !this.dialogBoleto
            this.load = true
            this.$http({
                method: "post",
                url: "/financeiro/fatura/boleto",
                data: {
                    fatura_id: this.fatura.id
                }
            }).then(response => {
                // console.log(response)
                if (response.data.mensagem == "ok") {
                    this.boleto = response.data.boleto
                }else{
                    this.dialogBoleto = !this.dialogBoleto
                }
                this.load = false
            })
        },
        openPix: function () {
            this.dialogPix = !this.dialogPix
            this.load = true
            this.$http({
                method: "post",
                url: "/financeiro/fatura/pix",
                data: {
                    fatura_id: this.fatura.id
                }
            }).then(response => {
                if (response.data.mensagem == "ok") {
                    this.pix = response.data.pix
                }else{
                    this.dialogPix = !this.dialogPix
                }
                this.load = false
            })
        },
        copiarCodigodeBarras: function(){
            navigator.clipboard.writeText(this.boleto.codigo_boleto)
        },
        openLinkBoleto: function () {
            window.open(this.boleto.url_boleto, "_blank")
        },
        openShare: function () {
            navigator.clipboard.writeText(this.boleto.url_boleto)
        },
        copiarCodigoPix: function(){
            navigator.clipboard.writeText(this.pix.codigo)
        },
        openLinkPix: function () {
            window.open(this.pix.url, "_blank")
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    p
        color: #fff
        font-size: 1.5em
        font-weight: bold
</style>